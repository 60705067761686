












import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import CarsSharedService, { CarsSharedServiceS } from '../../cars-shared.service';

@Component({
    components: {
        CustomSwitch,
    },
})
export default class RatesGraphTypeChanger extends Vue {
    @Inject(CarsSharedServiceS) protected carsSharedService!: CarsSharedService;

    @Prop({
        type: Boolean,
        default: false,
    })
    isChecked!: boolean;

    handleChange(checked: boolean) {
        this.$emit('change', checked);
    }

    get tooltipText() {
        return 'Toggle between your cars rates\n'
            + 'vs. compset price and your cars rates\n'
            + 'vs. all competitors\' individual prices';
    }
}
